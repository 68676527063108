import React from "react";
import Layout from "../components/layout/Layout";
import ListFeature from "../components/ListFeature";
import { certificatiProfessionali } from "../data/certificati-professionali/certificati-professionali";
import ContactSection from "../components/home-page/ContactSection";
import Seo from '../components/layout/SEO';

const CertificatiProfessionali = () => {
  return (
    <Layout>
      <Seo
        title="Patentino Muletto, KA, KB, CQC | Autoscuola Faro"
        description={"A Pisa, organizziamo corsi per acquisire le certificazioni professionali KA, KB, CQC e per il patentino del muletto."}
        keywords={[
          "certificato KA e KB",
          "prendere certificato CQC",
          "patentino muletto Pisa"
        ]}

      />
      <ListFeature list={certificatiProfessionali} />
      <ContactSection />
    </Layout>
  );
};

export default CertificatiProfessionali;
