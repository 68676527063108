import { faTaxi, faTruckMoving, faBus, faTruckRampBox} from '@fortawesome/free-solid-svg-icons';
export const certificatiProfessionali = {
    title: "Certificati professionali",
    subtitle: "Presso l'Autoscuola Faro a Pisa è possibile seguire i corsi per il conseguimento dei seguenti certificati professionali",
    list: [
        {
            id: "cert1",
            title: "Certificati KA e KB",
            link: "/certificati-professionali/certificati-ka-kb",
            icon: faTaxi,
        },
        {
            id: "cert2",
            title: "Certificati CQC merci",
            link: "/certificati-professionali/certificati-cqc-merci",
            icon: faTruckMoving,
        },
        {
            id: "cert3",
            title: "Certificati CQC persone",
            link: "/certificati-professionali/certificati-cqc-persone",
            icon: faBus,
        },
        {
            id: "cert4",
            title: "Patentino muletto",
            link: "/certificati-professionali/patentino-muletto",
            icon: faTruckRampBox,
        }


    ],
    buttonText: null
}